import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ArrowRightOnRectangleIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { useAuth0 } from '@auth0/auth0-react';

export default function NavBarMenu() {
    const { user, logout } = useAuth0();

    return (
        <div className="w-56 text-right">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        <div className="text-white mx-2 mt-auto pt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 my-auto">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                        </div>
                        <div className="bg-cyan-500 border rounded mx-2 rounded-full overflow-hidden">
                            <div className="text-white text-sm">
                                <img alt='Guest' src={user?.picture} className='w-8' />
                            </div>

                        </div>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${active ? 'bg-cyan-500 text-white' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        {active ? (
                                            <EditActiveIcon
                                                className="mr-2 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <EditInactiveIcon
                                                className="mr-2 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        )}
                                        Hello {user?.name}
                                    </button>
                                )}
                            </Menu.Item>

                        </div>
                        <div className="px-1 py-1">

                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                                        className={`${active ? 'bg-cyan-500 text-white' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        {active ? (
                                            <MoveActiveIcon
                                                className="mr-2 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <MoveInactiveIcon
                                                className="mr-2 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        )}
                                        Logout
                                    </button>
                                )}
                            </Menu.Item>
                        </div>

                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

function EditInactiveIcon(props: any) {
    return <UserCircleIcon className='stroke-cyan-400 w-5 mr-2' />
}

function EditActiveIcon(props: any) {
    return <UserCircleIcon className='stroke-full-400 w-5 mr-2' />
}

function MoveInactiveIcon(props: any) {
    return <ArrowRightOnRectangleIcon className='stroke-cyan-400 w-5 mr-2' />
}

function MoveActiveIcon(props: any) {
    return <ArrowRightOnRectangleIcon className='stroke-full-400 w-5 mr-2' />
}

