import { useEffect, useState } from "react";
import { UseArtists } from "../../data-layer/hooks/use-artists"

export function renderArtistList({ useArtists }: { useArtists: UseArtists; }): () => JSX.Element {

    return function ArtistList(): JSX.Element {
        const { list: artists, getMany, metadata } = useArtists();

        const [currentPage, setCurrentPage] = useState(1);
        const [totalPages, setTotalPages] = useState(metadata?.total);

        useEffect(() => {
            getMany({ q: 'ry' });
        }, []);

        const handlePageClick = (pageNumber: number) => {
            setCurrentPage(pageNumber);
            getMany({ q: 'ry', page: pageNumber });
        };

        const handlePrevClick = () => {
            setCurrentPage(prev => Math.max(prev - 1, 1));
        };

        const handleNextClick = () => {
            setCurrentPage(prev => Math.min(prev + 1, metadata?.total));
        };

        const renderPaginationButtons = () => {
            const buttons = [];
            const maxButtons = 10;
            const halfButtons = Math.floor(maxButtons / 2);

            if (metadata?.total <= maxButtons) {
                // If total pages are less than or equal to 10, show all buttons
                for (let i = 1; i <= metadata?.total; i++) {
                    buttons.push(
                        <button
                            key={i}
                            onClick={() => handlePageClick(i)}
                            className={i === currentPage ? 'active' : ''}
                        >
                            {i}
                        </button>
                    );
                }
            } else {
                for (let i = 1; i <= 5; i++) {
                    buttons.push(
                        <button
                            key={i}
                            onClick={() => handlePageClick(i)}
                            className={i === currentPage ? 'active' : ''}
                        >
                            {i}
                        </button>
                    );
                }

                if (currentPage > halfButtons + 1) {
                    buttons.push(<span key="ellipsis1">...</span>);
                }

                const startPage = Math.max(6, currentPage - 2);
                const endPage = Math.min(metadata?.total - 5, currentPage + 2);

                if (currentPage > halfButtons + 1 && currentPage < metadata?.total - halfButtons) {
                    for (let i = startPage; i <= endPage; i++) {
                        buttons.push(
                            <button
                                key={i}
                                onClick={() => handlePageClick(i)}
                                className={i === currentPage ? 'active' : ''}
                            >
                                {i}
                            </button>
                        );
                    }
                }

                if (currentPage < metadata?.total - halfButtons - 1) {
                    buttons.push(<span key="ellipsis2">...</span>);
                }

                for (let i = metadata?.total - 4; i <= metadata?.total; i++) {
                    buttons.push(
                        <button
                            key={i}
                            onClick={() => handlePageClick(i)}
                            className={i === currentPage ? 'active' : ''}
                        >
                            {i}
                        </button>
                    );
                }
            }

            return buttons;
        };

        return (
            <div className="at_page">
                <div className="at_breadcrumb">
                    <h2>Artist</h2>
                </div>
                <div className="at_artist_list">
                    <div className="row">
                        {
                            artists?.map((artist) => (
                                <div key={artist.id} className="col-md-2">
                                    <a href={"/artists/" + artist.id} className="at_artist_box">
                                        <div className="at_box_img">
                                            <span>{artist.name.slice(0, 1)}</span>
                                        </div>
                                        <div className="at_box_name">
                                            {artist.name}
                                        </div>
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                    <div className="at-pagi pagination">
                        <button onClick={handlePrevClick} disabled={currentPage === 1}> {`<`} </button>
                        {renderPaginationButtons()}
                        <button onClick={handleNextClick} disabled={currentPage === metadata?.total}>{`>`}</button>
                    </div>
                </div>
            </div>
        );
    }
}