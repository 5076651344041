import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { getBrands } from "../../module/brands";
import { getBrandsDetails } from "../../module/brands";
import { BrandsInsight } from "./brand-insights";
import { BrandsContact } from "./brand-contact";
import millify from 'millify';

export function BrandsDetails(): JSX.Element {

    const { id } = useParams();
    const [currentTab, setCurrentTab] = useState<string>('overview');
    const [brandDt, setBrandDt] = useState<any>();

    useEffect(() => {
        console.log(id);
        _getDetails();
    }, [])

    const _getDetails = () => {
        getBrandsDetails(id ?? '').then((result) => {
            setBrandDt(result);
        }).catch((e) => {
            console.log(e);
        })
    }
    const setTab = (value: string) => {
        if (currentTab !== value) {
            setCurrentTab(value);
        }
    }

    return (
        <div>
            <div className="text-black bg-neutral-100">
                <div id="cover">
                    <div className="bg-cover bg-center h-60" style={{ backgroundImage: `url(${'/cover.jpg'})` }} />
                    <div className="relative">
                        <div className="border-4 border-white absolute -top-24 left-6 rounded-full w-32 h-32 overflow-hidden">
                            <div className='at-artist-img'>{brandDt?.name.slice(0, 2)}</div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container-fluid">
                        <div className="flex mt-[40px]">
                            <div className="flex-grow">
                                <div className="flex items-center gap-[10px]">
                                    <div className="text-[26px] font-bold my-1">{brandDt?.name}</div>
                                    <div className="ml-2 text-[20px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-[20px]">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
                                        </svg>
                                    </div>
                                    <div className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-[20px]">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-[30px]">
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <img src="/social/youtube.png" alt="youtube" className="h-5" />
                                    </div>
                                    <div>
                                        <div className="text-xs text-gray-500">Youtube</div>
                                        <div className="bold text-md text-center">{millify(brandDt?.social_count?.youtube ?? 0)}</div>
                                    </div>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <img src="/social/twitter.png" alt="twitter" className="h-5" />
                                    </div>
                                    <div>
                                        <div className="text-xs text-gray-500">Twitter</div>
                                        <div className="bold text-md text-center">{millify(brandDt?.social_count?.twitter ?? 0)}</div>
                                    </div>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <img src="/social/facebook.png" alt="instagram" className="h-5" />
                                    </div>
                                    <div>
                                        <div className="text-xs text-gray-500">Facebook</div>
                                        <div className="bold text-md text-center">{millify(brandDt?.social_count?.facebook ?? 0)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <p>{brandDt?.description}</p>
                        </div>
                    </div>
                </section>



                <div className="p-4">
                    <div className="flex text-lg bold border-b border-gray-200 cursor-pointer">
                        <div onClick={() => { setTab('overview'); }} className={`${currentTab === 'overview' ? 'text-blue-500 border-b border-blue-500' : ''} py-2 mx-2 cursor`}>Overview</div>
                        <div onClick={() => { setTab('insights'); }} className={`${currentTab === 'insights' ? 'text-blue-500 border-b border-blue-500' : ''} py-2 mx-2 cursor`}>Insights</div>
                        <div onClick={() => { setTab('contact'); }} className={`${currentTab === 'contact' ? 'text-blue-500 border-b border-blue-500' : ''} py-2 mx-2 cursor`}>Contact</div>
                    </div>
                </div>
                {
                    currentTab === 'overview' ? <div className="h-80 text-[20px] text-center"> Coming soon.. </div> : null
                }
                {
                    currentTab === 'insights' ? <BrandsInsight brandDt={brandDt} /> : null
                }
                {
                    currentTab === 'contact' ? <BrandsContact brandDt={brandDt} /> : null
                }
            </div>
        </div>
    );

}