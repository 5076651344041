import React from 'react';
import { Artist } from '../../data-layer/model/artist';

export function BrandsContact({ brandDt }: { brandDt: any }): JSX.Element {
    return (
        <div className='container-fluid sec-pedding at-contect'>
            <h1>Want to connect? Just reach out to</h1>
            <h2>{(brandDt as any)?.name} <small>{(brandDt as any)?.industries?.join(', ')}</small></h2>
            <h3><small>Address:</small>{(brandDt as any)?.contact?.address || '-'}</h3>
            <h3><small>Phone:</small>{(brandDt as any)?.contact?.phone || '-'}</h3>
            <h3><small>Email:</small>{(brandDt as any)?.contact?.email || '-'}</h3>
        </div>
    );
}
