import ReactWordcloud, { Word } from "react-wordcloud";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";


const options = {
    colors: ["#000"],
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [20, 40],
    fontStyle: "normal",
    fontWeight: "200",
    padding: 0,
    rotations: 0,
    rotationAngles: [0, 0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
};

export function WordCloud({ data }: { data: Word[] }) {
    return (
        <div>
            <ReactWordcloud
                options={options as any}
                words={data.map((word) => ({
                    text: word.SeLabel,
                    value: word.value
                }))}
            />
        </div>
    );
}
