import { useEffect } from 'react';
import './App.css';
import { useAuth0, } from '@auth0/auth0-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getConfig } from './config';
import { createHeader } from './components/header-footer';
import { renderArtistList } from './pages/artist-list';
import { renderUnderConstruction } from './components/under-construction';
import { renderLoading } from './components/loading';
import { renderNotFound } from './components/not-found';
import { renderArtistDetails } from './pages/artist-details';
import { BrandsList } from './pages/brands';
import { BrandsDetails } from './pages/brands/brandsDetails';

import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
    const { isLoading, isAuthenticated, error, user, getAccessTokenSilently, loginWithRedirect, getIdTokenClaims } = useAuth0();
    const config = getConfig();
    const HeaderFooter = createHeader();

    const ArtistList = renderArtistList({ useArtists: config.hooks.useArtists });
    const Artist = renderArtistDetails({ useArtists: config.hooks.useArtists });

    const UnderConstruction = renderUnderConstruction();
    const NotFound = renderNotFound();
    const Loading = renderLoading();

    useEffect(() => {
        const getUserMetadata = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        redirect_uri: window.location.origin,
                        scope: 'openid profile email',
                    }
                });

                const idToken = await getIdTokenClaims();
                localStorage.setItem('access-token', accessToken);
                if (idToken?.__raw) {
                    localStorage.setItem('access-token', idToken?.__raw)
                }

            } catch (e: any) {
                console.log(e.message);
            }
        };

        getUserMetadata();
        // eslint-disable-next-line
    }, [user?.sub]);


    if (isLoading) {
        return <div><Loading /></div>;
    }

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isAuthenticated) {
        return (
            <div>
                <Routes>
                    <Route path='/' element={<Navigate to='/home' />}></Route>
                    <Route path="/" element={<HeaderFooter />}>

                        <Route path='/home' element={
                            <div className='md:container md:mx-auto'>
                                Home page
                            </div>
                        }
                        ></Route>
                        <Route path='/brands' element={<BrandsList />} />
                        <Route path='/brands/:id' element={<BrandsDetails />} />
                        <Route path='/artists' element={<ArtistList />}></Route>
                        <Route path='/artists/:id' element={<Artist />}></Route>

                        <Route path='/my-page' element={<UnderConstruction />}></Route>
                        <Route path='/concerts' element={<UnderConstruction />}></Route>
                        <Route path='/concerts/:id' element={<UnderConstruction />}></Route>
                        <Route path='/about-us' element={<UnderConstruction />}></Route>
                        <Route path='/contact' element={<UnderConstruction />}></Route>

                        <Route path='*' element={<NotFound />}></Route>

                        {/* <Route index element={
              <RoutesComponent config={config} />
            } /> */}
                        {/* <Route path="/v2/my-page" element={<NotDesigned name="My Page" />} />
                        <Route path="/v2/concerts" element={<ConcertList useConcerts={useConcerts} />} />
                        <Route path="/v2/concerts/:id" element={<ConcertDetails useConcerts={useConcerts} />} />
                        <Route path="/v2/artists/:id" element={<ArtistDetails useArtists={useArtists} />} />
                        <Route path="/v2/brands" element={<NotDesigned name="Brands" />} />
                        <Route path="/v2/about-us" element={<NotDesigned name="About Us" />} />
                        <Route path="/v2/contact" element={<NotDesigned name="Contact" />} /> */}
                    </Route>

                </Routes>
            </div>
        );
    } else {
        return <button onClick={() => loginWithRedirect()}>Log in</button>;
    }
}

export default App;
