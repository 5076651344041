import React from 'react';
import { Artist } from '../../data-layer/model/artist';

export function createArtistContact(): (
    { artist }: { artist: Artist }) => JSX.Element {
    // eslint-disable-next-line
    return function ArtistContact({ artist }: { artist: Artist; }): JSX.Element {
        return (
            <div className='container-fluid sec-pedding at-contect'>
                <h1>Want to connect? Just reach out to</h1>
                <h2>{(artist as any)?.name} <small>{(artist as any)?.contact?.role || 'Manager'}</small></h2>
                <h3><small>Address:</small>{(artist as any)?.contact?.address || '-'}</h3>
                <h3><small>Phone:</small>{(artist as any)?.contact?.phone || '-'}</h3>
                <h3><small>Email:</small>{(artist as any)?.contact?.email || '-'}</h3>
            </div>
        );
    };
}
