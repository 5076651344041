import _ from 'underscore';
import { Link } from 'react-router-dom';
import { Artist } from '../../data-layer/model/artist';
import { LineChart } from '../../components/line-chart';
import moment from 'moment';

export function createArtistOverview(): (
    { artist }: { artist: Artist }) => JSX.Element {
    // eslint-disable-next-line
    return function ArtistOverview({ artist }: { artist: Artist; }): JSX.Element {
        return (
            <div>
                <div className='container-fluid at_chart'>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="at-chat-box">
                                <div>
                                    <h2>Popularity</h2>
                                </div>
                                <LineChart title='Popularity' dataPoints={
                                    _.times(12, () => Math.floor(Math.random() * 100))
                                } xAxisPoints={_.times(12, (idx) => moment().subtract(idx, 'M').format('MMM YY')).reverse()} />
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="at-chat-box">
                                <div>
                                    <h2>Affinity</h2>
                                </div>
                                <LineChart title='Affinity' dataPoints={
                                    _.times(12, () => Math.floor(Math.random() * 100))
                                } xAxisPoints={_.times(12, (idx) => moment().subtract(idx, 'M').format('MMM YY')).reverse()} />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="my-2">
                    <div className="bold text-md px-4 mx-4">
                        Latest release
                    </div>
                    <div className="bold text-xs px-4 mx-4">
                        <img alt="youtube" src="/social/youtube.png" className="inline w-4 h-3" />
                        Youtube
                    </div>
                    <div className="flex flex-row py-2 px-4">
                        {
                            [1, 2, 3].map((c) => (
                                <div key={c} className="basis-1/3 mx-2">
                                    <div className="text-center my-auto mx-auto">
                                        <iframe title="bones" className="w-full h-60 border border-gray-200 rounded-lg" src="https://www.youtube.com/embed/uVvKQ1j13iE" />
                                    </div>
                                    <div className="ml-2">
                                        <div className="bold text-sm">Bones</div>
                                        <div className="text-xs text-gray-500">289K views - 2 months ago</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="my-2">
                    <div className="bold text-xs px-4 mx-4">
                        <img alt="spotify" src="/social/spotify.png" className="inline w-4 h-3" />
                        Spotify
                    </div>
                    <div className="flex flex-row py-2 px-4">
                        {
                            [1, 2, 3, 4].map((c) => (
                                <div key={c} className="basis-1/3 mx-2">
                                    <div className="text-center my-auto mx-auto">
                                        <img src={`
                    https://picsum.photos/id/${Math.floor(Math.random() * 100)}/200/300
                    `} alt={`${c}`} className="h-52 w-full border border-gray-200 rounded-lg" />
                                    </div>
                                    <div className="ml-2">
                                        <div className="bold text-sm">Bones</div>
                                        <div className="text-xs text-gray-500">2 months ago</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="my-2">
                    <div className="bold text-md px-4 mx-4">
                        Similar Artists
                    </div>
                    <div className="flex flex-row py-2 px-4">
                        {
                            (artist.related_artists.slice(0, 3)).map((c, idx) => (
                                <div key={idx} className="basis-1/3 mx-2">
                                    <div className="text-center my-auto mx-auto">
                                        <Link to={`/artists/${c.id}`}>
                                            <img alt={`${c.name}`} className="object-fill mx-auto h-60 border border-gray-200 rounded-lg "
                                                src={c.img || `https://randomuser.me/api/portraits/${idx % 2 === 0 ? 'men' : 'women'}/${Math.floor(Math.random() * 100)}.jpg`} />
                                        </Link>
                                    </div>
                                    <div className="ml-2">
                                        <div className="bold text-sm">{c.name}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    };
}
