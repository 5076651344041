import { UseArtists, createUseArtists } from "./data-layer/hooks/use-artists"
import { HttpArtist } from "./data-layer/repositories/http/artist"

export type Config = {
  hooks: {
    useArtists: UseArtists
  }
}

export function getConfig(): Config {

  const artistService = new HttpArtist();
  const useArtists = createUseArtists({ useService: () => artistService })

  return {
    hooks: {
      useArtists
    }
  }
}