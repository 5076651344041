
import { AxiosInstanceProvider } from "../data-layer/provider/axios-instance";

const domain = process.env.REACT_APP_BASE_URL || 'https://dev-api.cuttime.fm';
const axiosInstance = new AxiosInstanceProvider();

export const getBrands = async (param: any) => {
    const axios = await axiosInstance.getAuthorizedInstance({ apiUrl: domain });
    const result = await axios.get('/brands', { ...param }).then(res => {
        return res.data;
    })
    return result;
}

export const getBrandsDetails = async (id: string) => {
    const axios = await axiosInstance.getAuthorizedInstance({ apiUrl: domain });
    const result = await axios.get('/brands/' + id).then(res => {
        return res.data;
    })
    return result;
}