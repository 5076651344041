
import 'chart.js/auto'
import { useRef } from 'react';
import { Bar } from 'react-chartjs-2';


export function HorizontalChart({
    title,
    dataPoints,
    xAxisPoints
}: {
    title: string;
    dataPoints: Array<number>;
    xAxisPoints: Array<string>;
}): JSX.Element {
    const ref = useRef();
    // const labels =  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', "Jul"]
    const data = {
        labels: xAxisPoints,
        datasets: [
            {
                label: title,
                borderColor: '#42cbf5',
                backgroundColor: '#92ebf0',
                data: dataPoints, // [65, 59, 80, 81, 56, 55, 40],  
            }
        ]
    };
    return <Bar ref={ref} data={data} options={{
        indexAxis: 'y'
    }} />
}