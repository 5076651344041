import { ArtistInterface } from "../../interfaces/artist";
import { Artist } from "../../model/artist";
import { ArtistTour } from "../../model/artist-tour";
import { AxiosInstanceProvider } from "../../provider/axios-instance";


export class HttpArtist implements ArtistInterface {
    axiosInstance
    domain = process.env.REACT_APP_BASE_URL || 'https://dev-api.cuttime.fm';

    constructor() {
        this.axiosInstance = new AxiosInstanceProvider();
    }

    async set(a: Artist): Promise<Artist> {
        return a;
    }

    async getById(id: string): Promise<Artist> {
        const axios = await this.axiosInstance.getAuthorizedInstance({ apiUrl: this.domain });
        const result = await axios.get(`/artists/${id}`).then(res => {
            return res.data;
        })
        try {
            const topTracks = await axios.get(`/artists/${id}/top-tracks`).then(res => {
                return res.data;
            })
            console.log(topTracks);
        } catch (e) {
            console.log('could call top-tracks')
        }
        return result as Artist;
    }

    async getMany(filter: any): Promise<Artist[]> {
        const axios = await this.axiosInstance.getAuthorizedInstance({ apiUrl: this.domain });
        const result = await axios.get('/artists', {
            params: {
                ...filter,
                limit: 20,
            }
        }).then(res => {
            return res.data;
        })
        return result as Artist[];
    }

    async getToursById(id: string): Promise<ArtistTour[]> {
        const axios = await this.axiosInstance.getAuthorizedInstance({ apiUrl: this.domain });
        const result = await axios.get(`/artists/${id}/events`).then(res => {
            return res.data;
        });
        return result as ArtistTour[];
    }
}
