import _ from 'underscore';
import { Artist } from '../../data-layer/model/artist';
import moment from 'moment';
import millify from 'millify';
import { WordCloud } from '../../components/word-cloud';


export function createArtistTour(): ({ artist }: { artist: Artist }) => JSX.Element {

    return function ArtistTours({ artist }: { artist: Artist; }): JSX.Element {
        return (
            <div className='container-fluid sec-pedding'>
                <div className="at-tour-list">
                    <ul>
                        <li>
                            <a href="">
                                <div className="row">
                                    <div className="col-md-3"><h3><small>Date</small>31 Dec 2022</h3></div>
                                    <div className="col-md-6"><h3><small>Event</small>New year eve</h3></div>
                                    <div className="col-md-3">Fine Line, Minneapolis</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="">
                                <div className="row">
                                    <div className="col-md-3"><h3><small>Date</small>31 Dec 2022</h3></div>
                                    <div className="col-md-6"><h3><small>Event</small>Testing concert curation</h3></div>
                                    <div className="col-md-3">Fine Line, Minneapolis</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="">
                                <div className="row">
                                    <div className="col-md-3"><h3><small>Date</small>31 Dec 2022</h3></div>
                                    <div className="col-md-6"><h3><small>Event</small>AMCE concert</h3></div>
                                    <div className="col-md-3">Fine Line, Minneapolis</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}