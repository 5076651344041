import _ from 'underscore';
import { Artist } from '../../data-layer/model/artist';
import moment from 'moment';
import millify from 'millify';
import { WordCloud } from '../../components/word-cloud';
import { HorizontalChart } from '../../components/horizontal-bar-chart';


export function createArtistInsights(): (
    { artist }: { artist: Artist }) => JSX.Element {


    // eslint-disable-next-line
    return function ArtistInsights({ artist }: { artist: Artist; }): JSX.Element {

        const locations: any = [];
        _.keys(artist.demographics.location).forEach(key => {
            locations.push(
                {
                    SeLabel: key.split('_').map(x => `${x.charAt(0).toUpperCase()}${x.substring(1)}`).join(' '),
                    value: `${parseInt(artist.demographics.location[key].popularity) || 1}`
                },
            )
        })

        return (
            <div>
                {/* <div className='container-fluid'>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <h2>Gender Followings</h2>
                                <div className="cnt">
                                    <div>
                                        <img src="/male.png" alt="" />
                                        <div className="cnt-count">
                                            <h3><small>Male</small> {millify(artist.demographics?.gender?.male as any || 0)}</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <img src="/female.png" alt="" />
                                        <div className="cnt-count">
                                            <h3><small>Female</small> {millify(artist.demographics?.gender?.male as any || 0)}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <h2>Age Followings</h2>
                                <div className="cnt">
                                    <div className="cnt-count">
                                        <h3><small>18 - 29</small> {millify(artist.demographics?.age?.a18_29 as any || 0)}</h3>
                                    </div>
                                    <div className="cnt-count">
                                        <h3><small>30 - 44</small> {millify(artist.demographics?.age?.a30_44 as any || 0)}</h3>
                                    </div>
                                    <div className="cnt-count">
                                        <h3><small>45 - 60</small> {millify(artist.demographics?.age?.a45_60 as any || 0)}</h3>
                                    </div>
                                    <div className="cnt-count">
                                        <h3><small>60+</small> {millify(artist.demographics?.age?.a60_plus as any || 0)}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <h2>Top location followers</h2>
                                <WordCloud data={locations as any} />
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="flex flex-row flex-wrap py-2 px-4">
                    <div className="w-1/3 p-2">
                        <div className='h-48 bg-white border border-gray-200 rounded-lg'>
                            <div className="h-full p-2">
                                <div className='text-lg font-semibold'>Gender Followings</div>
                                <div className='flex h-4/5'>
                                    <div className='w-1/2 flex justify-center'>
                                        <div className='my-auto'>
                                            <img src='/male.png' alt='male' className='w-12' />
                                        </div>
                                        <div className='my-auto'>
                                            <div>Male</div>
                                            <div className='text-3xl'>{millify(artist.demographics?.gender?.male as any || 0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/2 flex justify-center'>
                                        <div className='my-auto'>
                                            <img src='/female.png' alt='female' className='w-12' />
                                        </div>
                                        <div className='my-auto'>
                                            <div>Female</div>
                                            <div className='text-3xl'>{millify(artist.demographics?.gender?.female as any || 0)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3 p-2">
                        <div className='h-48 bg-white border border-gray-200 rounded-lg'>
                            <div className="h-full p-2">
                                <div className='text-lg font-semibold'>Age Followings</div>
                                <div className='flex h-4/5'>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div>18-29</div>
                                            <div className='text-3xl'>{millify(artist.demographics?.age?.a18_29 as any || 0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div>25-44</div>
                                            <div className='text-3xl'>{millify(artist.demographics?.age?.a30_44 as any || 0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div>45-60</div>
                                            <div className='text-3xl'>{millify(artist.demographics?.age?.a45_60 as any || 0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div>60+</div>
                                            <div className='text-3xl'>{millify(artist.demographics?.age?.a60_plus as any || 0)}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="w-1/3 p-2">
                        <div className='h-48 bg-white border border-gray-200 rounded-lg'>
                            <div className="h-full p-2">
                                <div className='text-lg font-semibold'>Top Location Followers</div>
                                <div className='flex h-4/5 justify-center'>
                                    <WordCloud data={locations as any} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3 p-2">
                        <div className='h-48 bg-white border border-gray-200 rounded-lg'>
                            <div className="h-full p-2">
                                <div className='text-lg font-semibold'>
                                    <img alt="youtube" src="/social/youtube.png" className="inline w-8 h-5 mr-1" />
                                    Youtube
                                </div>
                                <div className='flex h-4/5'>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>Subscribers</div>
                                            <div className='text-3xl  text-center'>{millify(0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>Avg. Views</div>
                                            <div className='text-3xl  text-center'>{millify(0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>Avg views per video</div>
                                            <div className='text-3xl  text-center'>{millify(0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>Avg. engagement</div>
                                            <div className='text-3xl text-center'>{millify(0)}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="w-1/3 p-2">
                        <div className='h-48 bg-white border border-gray-200 rounded-lg'>
                            <div className="h-full p-2">
                                <div className='text-lg font-semibold'>
                                    <img alt="youtube" src="/social/spotify.png" className="inline w-5 h-5 mr-1" />
                                    Spotify
                                </div>
                                <div className='flex h-4/5'>
                                    <div className='w-1/3 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>Playcount</div>
                                            <div className='text-3xl  text-center'>{millify(0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/3 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>Listeners</div>
                                            <div className='text-3xl  text-center'>{millify(0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/3 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>Popularity</div>
                                            <div className='text-3xl  text-center'>{millify(0)}</div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="w-1/3 p-2">
                        <div className='h-48 bg-white border border-gray-200 rounded-lg'>
                            <div className="h-full p-2">
                                <div className='text-lg font-semibold'>
                                    <img alt="twitter" src="/social/twitter.png" className="inline w-8 h-5 mr-1" />
                                    Twitter
                                </div>
                                <div className='flex h-4/5'>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>Followers</div>
                                            <div className='text-3xl  text-center'>{millify(0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>Engagement ratio</div>
                                            <div className='text-3xl  text-center'>{millify(0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>No. of tweets</div>
                                            <div className='text-3xl  text-center'>{millify(0)}</div>
                                        </div>
                                    </div>
                                    <div className='w-1/4 flex justify-center'>
                                        <div className='my-auto'>
                                            <div className='text-center'>Avg. likes</div>
                                            <div className='text-3xl text-center'>{millify(0)}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-2">
                    <div className="bold text-lg px-4 mx-4">
                        Sentiment Analysis
                    </div>
                    <div className="bold text-sm px-4 mx-4">
                        <img alt="youtube" src="/social/youtube.png" className="inline w-4 h-3" />
                        Youtube
                    </div>
                    <div className="flex flex-row flex-wrap py-2 px-4">
                        <div className="w-1/2 p-2">
                            <div className='h-80 bg-white border border-gray-200 rounded-lg'>
                                <div className="h-full p-2">
                                    <div className='text-xl font-semibold'>
                                        Lyrics
                                    </div>
                                    <div className='flex justify-center h-4/5'>
                                        {/* Popularity Chart will come here */}
                                        <HorizontalChart title='Popularity' dataPoints={
                                            _.times(12, () => Math.floor(Math.random() * 100))
                                        } xAxisPoints={_.times(12, (idx) => moment().subtract(idx, 'M').format('MMM YY')).reverse()} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 p-2">
                            <div className='h-80 bg-white border border-gray-200 rounded-lg'>
                                <div className="h-full p-2">
                                    <div className='text-xl font-semibold'>
                                        Comments
                                    </div>
                                    <div className='flex justify-center h-4/5'>
                                        {/* Popularity Chart will come here */}
                                        <HorizontalChart title='Popularity' dataPoints={
                                            _.times(12, () => Math.floor(Math.random() * 100))
                                        } xAxisPoints={_.times(12, (idx) => moment().subtract(idx, 'M').format('MMM YY')).reverse()} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 p-2">
                            <div className='h-80 bg-white border border-gray-200 rounded-lg'>
                                <div className="h-full p-2">
                                    <div className='text-xl font-semibold'>
                                        Top 10 words in Comments
                                    </div>
                                    <div className='flex justify-center h-4/5'>
                                        {/* Popularity Chart will come here */}
                                        <WordCloud data={locations as any} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 p-2">
                            <div className='h-80 bg-white border border-gray-200 rounded-lg'>
                                <div className="h-full p-2">
                                    <div className='text-xl font-semibold'>
                                        Song Sentiment
                                    </div>
                                    <div className='flex justify-center h-4/5'>
                                        {/* Popularity Chart will come here */}
                                        <HorizontalChart title='Popularity' dataPoints={
                                            _.times(12, () => Math.floor(Math.random() * 100))
                                        } xAxisPoints={_.times(12, (idx) => moment().subtract(idx, 'M').format('MMM YY')).reverse()} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
