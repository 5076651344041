
import 'chart.js/auto'
import { useRef } from 'react';
import { Line } from 'react-chartjs-2';


export function LineChart({
    title,
    dataPoints,
    xAxisPoints
}: {
    title: string;
    dataPoints: Array<number>;
    xAxisPoints: Array<string>;
}): JSX.Element {
    const ref = useRef();
    // const labels =  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', "Jul"]
    const data = {
        labels: xAxisPoints,
        datasets: [
            {
                label: title,
                borderColor: '#42cbf5',
                data: dataPoints, // [65, 59, 80, 81, 56, 55, 40],
                fill: {
                    target: 'origin',
                    above: '#92ebf0', // 'rgb(255, 0, 0)',   // Area will be red above the origin
                    below: 'rgb(0, 0, 255)'    // And blue below the origin
                },

            }
        ]
    };
    const options = {
        scales: {
            y: {
                display: false,
                grid: {
                    display: false
                }
            },
            x: {
                grid: {
                    display: false
                }
            },
        }
    }
    return <Line ref={ref} data={data} options={options} />
}