import axios, { AxiosInstance } from 'axios';

export class AxiosInstanceProvider {

    async getPlainInstance(data: { apiUrl: string }): Promise<AxiosInstance> {
        return axios.create({
            baseURL: data.apiUrl,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
    }

    async getAuthorizedInstance(data: {
        apiUrl: string;
    }): Promise<AxiosInstance> {
        const token = await this.getToken();
        return axios.create({
            baseURL: data.apiUrl,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
    }

    private async getToken(): Promise<string> {
        // const token = localStorage.getItem('cuttime-token');
        // console.log('cuttime token', token);
        const token = localStorage.getItem('access-token');

        if (!token) {
            throw new Error('User not logged in');
        }

        return token;
    }
}
