import { useEffect, useState } from "react";
import { getBrands } from "../../module/brands";

export function BrandsList(): JSX.Element {

    const [currentPage, setCurrentPage] = useState(1);
    const [brandList, setBrandList] = useState([]);
    const [totalPages, setTotalPages] = useState();

    useEffect(() => {
        _getData();
    }, []);

    const _getData = () => {
        const param = {
            limit: 20,
        }
        getBrands(param).then(result => {
            console.log(result);
            setBrandList(result);
        }).catch(e => {
            console.log(e);
        })
    }

    return (
        <div className="at_page">
            <div className="at_breadcrumb">
                <h2>Brands</h2>
            </div>
            <div className="at_artist_list">
                <div className="row">
                    {
                        brandList?.map((brand: any) => (
                            <div key={brand.id} className="col-md-2">
                                <a href={"/brands/" + brand.id} className="at_artist_box">
                                    <div className="at_box_img">
                                        <span>{brand.name.slice(0, 1)}</span>
                                    </div>
                                    <div className="at_box_name">
                                        {brand.name}
                                    </div>
                                </a>
                            </div>
                        ))
                    }
                </div>
                {/* <div className="at-pagi pagination">
                    <button onClick={handlePrevClick} disabled={currentPage === 1}> {`<`} </button>
                    {renderPaginationButtons()}
                    <button onClick={handleNextClick} disabled={currentPage === metadata?.total}>{`>`}</button>
                </div> */}
            </div>
        </div>
    );
}