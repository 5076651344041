import _ from 'underscore';
import { Artist } from '../../data-layer/model/artist';
import moment from 'moment';
import millify from 'millify';
import { WordCloud } from '../../components/word-cloud';
import { HorizontalChart } from '../../components/horizontal-bar-chart';


export function BrandsInsight({ brandDt }: { brandDt: any }): JSX.Element {

    const locations: any = [];
    _.keys(brandDt?.demographics[0]?.location).forEach(key => {
        locations.push(
            {
                SeLabel: key.split('_').map(x => `${x.charAt(0).toUpperCase()}${x.substring(1)}`).join(' '),
                value: `${parseInt(brandDt?.demographics[0]?.location[key]) || 1}`
            },
        )
    })

    return (
        <div className='container-fluid'>
            <div className="row ev-bd-card">
                <div className="col-md-4">
                    <div className='card'>
                        <div className='card-body'>
                            <div className='text-lg font-semibold mb-4'>Gender Followings</div>
                            <div className='flex gap-5'>
                                <div className='flex gap-2 items-center'>
                                    <div className=''>
                                        <img src='/male.png' alt='male' className='w-12' />
                                    </div>
                                    <div className=''>
                                        <h3 className='text-[20px] mb-0'>Male</h3>
                                        <div className='text-3xl'>{millify(brandDt?.demographics[0]?.gender?.male ?? 0)}</div>
                                    </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <div className=''>
                                        <img src='/female.png' alt='male' className='w-12' />
                                    </div>
                                    <div className=''>
                                        <h3 className='text-[20px] mb-0'>Female</h3>
                                        <div className='text-3xl'>{millify(brandDt?.demographics[0]?.gender?.male ?? 0)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className='card'>
                        <div className='card-body'>
                            <div className='text-lg font-semibold mb-4'>Age Followings</div>
                            <div className='flex gap-5 justify-between text-center'>
                                <div className='my-auto'>
                                    <div>18-29</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.age?.a18_29 ?? 0)}</div>
                                </div>
                                <div className='my-auto'>
                                    <div>25-44</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.age?.a30_44 ?? 0)}</div>
                                </div>
                                <div className='my-auto'>
                                    <div>45-60</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.age?.a45_60 ?? 0)}</div>
                                </div>
                                <div className='my-auto'>
                                    <div>60+</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.age?.a60_plus ?? 0)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className='card'>
                        <div className='card-body'>
                            <div className='text-lg font-semibold mb-4'>Top Location Followers</div>
                            <div className='ev-bd-text'>
                                <WordCloud data={locations as any} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className='card'>
                        <div className='card-body'>
                            <div className='text-lg font-semibold mb-4'>Ethnicity</div>
                            <div className='flex gap-5 justify-between text-center'>
                                <div className='my-auto'>
                                    <div>Black</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.ethnicity?.black ?? 0)}</div>
                                </div>
                                <div className='my-auto'>
                                    <div>White</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.ethnicity?.white ?? 0)}</div>
                                </div>
                                <div className='my-auto'>
                                    <div>Hispanic</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.ethnicity?.hispanic ?? 0)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className='card'>
                        <div className='card-body'>
                            <div className='text-lg font-semibold mb-4'>Income</div>
                            <div className='flex gap-5 justify-between text-center'>
                                <div className='my-auto'>
                                    <div>25-50</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.income?.k_25_50 ?? 0)}</div>
                                </div>
                                <div className='my-auto'>
                                    <div>50-100</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.income?.k_50_100 ?? 0)}</div>
                                </div>
                                <div className='my-auto'>
                                    <div>100-150</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.income?.k_100_150 ?? 0)}</div>
                                </div>
                                <div className='my-auto'>
                                    <div>150+</div>
                                    <div className='text-3xl'>{millify(brandDt?.demographics[0]?.income?.k_150_plus ?? 0)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-2 mb-5">
                <div className="font-bold text-[22px] mb-3">
                    Sentiment Analysis
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className='card !h-80'>
                            <div className='card-body h-full'>
                                <div className='text-lg font-semibold mb-4'>Archetypes</div>
                                <div className='h-[80%]'>
                                    <HorizontalChart title='Popularity'
                                        dataPoints={brandDt?.archetypes.map((v: any) => v?.value ?? 0)}
                                        xAxisPoints={brandDt?.archetypes.map((v: any) => v?.name ?? '-')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='card !h-80'>
                            <div className='card-body h-full'>
                                <div className='text-lg font-semibold mb-4'>Top 10 words in Comments</div>
                                <div className='h-[80%]'>
                                    <WordCloud data={locations as any} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
