import { useState } from "react";
import { Artist } from "../model/artist";
import { ArtistTour } from "../model/artist-tour";
import { ArtistInterface } from "../interfaces/artist";


export type UseArtists = () => {
    data: Artist | undefined;
    list: Array<Artist>;
    loading: boolean;
    metadata: any;

    get: (id: string) => void;
    getSync: (id: string) => Promise<Artist>;

    set: (s: Artist) => void;
    syncSet: (s: Artist) => Promise<Artist>;

    getMany: (s: any) => void;
    getManySync: (s: any) => Promise<Artist[]>;


    getToursSync: (id: string) => Promise<ArtistTour[]>;

}

export function createUseArtists({ useService }: {
    useService: () => ArtistInterface;
}): UseArtists {
    return () => {
        const [resource, setResource] = useState<Artist | undefined>();
        const [resources, setResources] = useState<Array<Artist>>([]);
        const [loading, setLoading] = useState<boolean>(false);
        const [metadata, setmetadata] = useState<any>(null);

        const resourceService = useService();

        const apiCall = async (id: string) => {
            setLoading(true);
            const ra = await resourceService.getById(id);
            setResource(ra);
            setLoading(false);
        };

        const set = async (art: Artist) => {
            setLoading(true);
            const c = await resourceService.set(art);
            setResource(c);
            setLoading(false);
        };

        // pass options to disable state change inside this hook
        const syncSet = async (
            art: Artist
            // options?: { enableLoading?: boolean; enableResource?: boolean }
        ) => {
            setLoading(true);
            const c = await resourceService.set(art);
            setResource(c);
            setLoading(false);
            return c;
        };

        // pass options to disable state change inside this hook
        const getSync = async (
            id: string
        ) => {
            setLoading(true);
            const c = await resourceService.getById(id);
            setResource(c);
            setLoading(false);
            return c;
        };

        const getAll = async (filter?: any) => {
            setLoading(true);
            const r = (await resourceService.getMany({ ...filter } as any));
            console.log(r);
            setResources(r?.data);
            setmetadata(r?.metadata);
            setLoading(false);
        };

        const getManySync = async (filter?: any) => {
            setLoading(true);
            const c = (await resourceService.getMany({
                ...filter,
            })) as Artist[];
            setResources(c);
            setLoading(false);
            return c;
        };

        const getToursSync = async (id: string) => {
            setLoading(true);
            const c = await resourceService.getToursById(id);
            setLoading(false);
            return c;
        }

        return {
            data: resource,
            list: resources,
            loading,
            metadata,
            set: (s: Artist) => {
                set(s);
            },
            syncSet: (s: Artist) => syncSet(s),

            getSync: (id: string) => getSync(id),
            get: (id: string) => {
                apiCall(id);
            },

            getMany: (filter?: any) => {
                getAll(filter);
            },
            getManySync,
            getToursSync,
        };
    };
}